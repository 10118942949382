<template>
  <img :src="teamLogo()" :class="getClass()" />
</template>

<script setup>
import { useRoute } from 'vue-router';
import { defineProps } from 'vue';

const props = defineProps(['link', 'teamId', 'size']);

const route = useRoute();

const teamLogo = () => {
  if (props.link) return props.link;

  const sport = route.fullPath.split('/')[1];

  if (sport && props.teamId) {
    return `${sport}-logos/${props?.teamId}.png`;
  }

  return '';
};

const getClass = () => props.size;
</script>

<style scoped>
img.large {
  height: 80px;
}
img {
  height: 20px;
}
</style>