<template>
  <NDataTable
    :columns="columns"
    :data="data"
    :size="size"
    :bordered="true"
    :loading="loading"
    scroll-x="true"
  />
</template>

<script setup>
import { NDataTable } from 'naive-ui';
import { ref, toRef, defineProps } from 'vue';

const props = defineProps(['columns', 'data', 'loading']);

const columns = toRef(props, 'columns') || [];
const data = toRef(props, 'data') || [];
const loading = toRef(props, 'loading') || true;

const size = ref('small');
</script>