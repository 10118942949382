
export const SPORT = 'cfb';
export const REGIONS = 'us';
export const ODDS_FORMAT = 'american';
export const BOOK_MAKERS = 'draftkings';
export const AVAILABLE_MARKETS = {
  h2h: 'h2h',
  spreads: 'spreads',
  totals: 'totals',
};
export const AVAILABLE_PLAYER_PROPS = [
  'player_pass_tds',
  'player_pass_yds',
  'player_pass_completions',
  'player_pass_attempts',
  'player_pass_interceptions',
  'player_pass_longest_completion',
  'player_rush_yds',
  'player_rush_attempts',
  'player_rush_longest',
  'player_receptions',
  'player_reception_yds',
  'player_reception_longest',
  'player_kicking_points',
  'player_field_goals',
  'player_tackles_assists',
  'player_1st_td',
  'player_last_td',
  'player_anytime_td',
];
export const ODDS_API_STUB = 'https://api.the-odds-api.com/v4/sports/americanfootball_ncaaf/odds';

export const reduceStats = (data) => {
  return data.reduce((result, item) => {
    const { team } = item;
    const { statName, statValue } = item;
  
    result[statName] = statValue;
    result.team = team;
  
    return result;
  }, {});
};

export const REPORT_TYPES = {
  PASSING: 'Passing',
  RUSHING: 'Rushing',
  TOTAL_OFFENSE: 'Total Offense',
  DEFENSE: 'Defense',
  TURNOVERS: 'Turnovers',
  PENALTIES: 'Penalties',
  RETURNS: 'Returns',
};

export const STAT_MAP = [
  {
    id: 'games',
    display: 'Games',
    tableOrder: 1,
    aspect: 'ALL',
  },

  {
    id: 'passCompletions',
    display: 'Pass Completions',
    tableOrder: 1,
    aspect: REPORT_TYPES.PASSING,
  },
  {
    id: 'passAttempts',
    display: 'Pass Attempts',
    tableOrder: 2,
    aspect: REPORT_TYPES.PASSING,
  },
  {
    id: 'netPassingYards',
    display: 'Net Passing Yards',
    tableOrder: 3,
    aspect: REPORT_TYPES.PASSING,
  },
  {
    id: 'passingTDs',
    display: 'Passing Touchdowns',
    tableOrder: 4,
    aspect: REPORT_TYPES.PASSING,
  },

  {
    id: 'rushingAttempts',
    display: 'Rushing Attempts',
    tableOrder: 1,
    aspect: REPORT_TYPES.RUSHING,
  },
  {
    id: 'rushingYards',
    display: 'Rushing Yards',
    tableOrder: 2,
    aspect: REPORT_TYPES.RUSHING,
  },
  {
    id: 'rushingTDs',
    display: 'Rushing Touchdowns',
    tableOrder: 3,
    aspect: REPORT_TYPES.RUSHING,
  },

  {
    id: 'totalYards',
    display: 'Total Yards',
    tableOrder: 1,
    aspect: REPORT_TYPES.TOTAL_OFFENSE,
  },
  {
    id: 'possessionTime',
    display: 'Possession Time',
    tableOrder: 2,
    aspect: REPORT_TYPES.TOTAL_OFFENSE,
  },
  {
    id: 'firstDowns',
    display: 'First Downs',
    tableOrder: 3,
    aspect: REPORT_TYPES.TOTAL_OFFENSE,
  },
  {
    id: 'thirdDowns',
    display: 'Third Downs',
    tableOrder: 4,
    aspect: REPORT_TYPES.TOTAL_OFFENSE,
  },
  {
    id: 'thirdDownConversions',
    display: 'Third Down Conversions',
    tableOrder: 5,
    aspect: REPORT_TYPES.TOTAL_OFFENSE,
  },
  {
    id: 'fourthDowns',
    display: 'Fourth Downs',
    tableOrder: 6,
    aspect: REPORT_TYPES.TOTAL_OFFENSE,
  },
  {
    id: 'fourthDownConversions',
    display: 'Fourth Down Conversions',
    tableOrder: 7,
    aspect: REPORT_TYPES.TOTAL_OFFENSE,
  },

  {
    id: 'fumblesLost',
    display: 'Fumbles',
    tableOrder: 1,
    aspect: REPORT_TYPES.TURNOVERS,
  },
  {
    id: 'passesIntercepted',
    display: 'Passes Intercepted',
    tableOrder: 2,
    aspect: REPORT_TYPES.TURNOVERS,
  },
  {
    id: 'turnovers',
    display: 'Turnovers',
    tableOrder: 3,
    aspect: REPORT_TYPES.TURNOVERS,
  },

  {
    id: 'penalties',
    display: 'Penalties',
    tableOrder: 1,
    aspect: REPORT_TYPES.PENALTIES,
  },
  {
    id: 'penaltyYards',
    display: 'Penalty Yards',
    tableOrder: 2,
    aspect: REPORT_TYPES.PENALTIES,
  },

  {
    id: 'sacks',
    display: 'Sacks',
    tableOrder: 1,
    aspect: REPORT_TYPES.DEFENSE,
  },
  {
    id: 'tacklesForLoss',
    display: 'Tackles for Loss',
    tableOrder: 2,
    aspect: REPORT_TYPES.DEFENSE,
  },
  {
    id: 'fumblesRecovered',
    display: 'Fumbles Recovered',
    tableOrder: 3,
    aspect: REPORT_TYPES.DEFENSE,
  },
  {
    id: 'interceptions',
    display: 'Interceptions',
    tableOrder: 4,
    aspect: REPORT_TYPES.DEFENSE,
  },
  {
    id: 'interceptionYards',
    display: 'Interception Yards',
    tableOrder: 5,
    aspect: REPORT_TYPES.DEFENSE,
  },
  {
    id: 'interceptionTDs',
    display: 'Interception Touchdowns',
    tableOrder: 6,
    aspect: REPORT_TYPES.DEFENSE,
  },

  {
    id: 'kickReturns',
    display: 'Kick Returns',
    tableOrder: 1,
    aspect: REPORT_TYPES.RETURNS,
  },
  {
    id: 'kickReturnYards',
    display: 'Kick Return Yards',
    tableOrder: 2,
    aspect: REPORT_TYPES.RETURNS,
  },
  {
    id: 'kickReturnTDs',
    display: 'Kick Return Touchdowns',
    tableOrder: 3,
    aspect: REPORT_TYPES.RETURNS,
  },
  {
    id: 'puntReturns',
    display: 'Punt Returns',
    tableOrder: 4,
    aspect: REPORT_TYPES.RETURNS,
  },
  {
    id: 'puntReturnYards',
    display: 'Punt Return Yards',
    tableOrder: 5,
    aspect: REPORT_TYPES.RETURNS,
  },
  {
    id: 'puntReturnTDs',
    display: 'Punt Return Touchdowns',
    tableOrder: 6,
    aspect: REPORT_TYPES.RETURNS,
  },
];

