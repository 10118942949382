<template>
  <EventsPage
    :sport="SPORTS.NBA"
    :fetch-events="fetchEventLines"
  />
</template>

<script setup>
import { SPORTS } from '@/util/shared';
import EventsPage from '@/pages/shared/EventsPage';

const fetchEventLines = async () => {};
</script>