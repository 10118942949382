<template>
  <NCard :title="stat">
    <NFlex justify="end">
      <NButton secondary @click="clearComparePlayer">Clear</NButton>
      <NSelect
        filterable
        placeholder="Compare Player"
        v-model:value="comparePlayer"
        :loading="comparePlayerLoading"
        :options="comparePlayerOptions"
        :on-update:value="updateComparePlayer"
      />
    </NFlex>
    <LineChart :chartData="chartData" :options="chartOptions" />
  </NCard>
</template>

<script setup>
import _ from 'lodash';
import { ref, computed, toRef, defineProps } from 'vue';
import { NFlex, NSelect, NCard, NButton } from 'naive-ui';
import { usePlayerDataFetch } from '@/composables/PlayerDataFetch';

import { LineChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const props = defineProps(['sport', 'stat', 'players', 'playerReportData']);

const stat = toRef(props, 'stat');
const players = toRef(props, 'players');
const playerReportData = toRef(props, 'playerReportData');

const chartOptions = ref({
  responsive: true,
});

const comparePlayer = ref(null);
const comparePlayerReportData = ref(null);
const comparePlayerLoading = ref(false);

const chartData = computed(() => {
  if (playerDatasetData.value) {
    return {
      labels: chartLabels.value,
      datasets: [
        {
          data: playerDatasetData.value,
          label: playerReportData.value.meta?.name,
          tension: 0.1,
          borderColor: playerReportData.value.meta?.teamColor,
        },
        {
          data: comparePlayerDatasetData.value,
          label: comparePlayerReportData.value?.meta?.name || 'compare_player',
          tension: 0.1,
          borderColor: comparePlayerReportData.value?.meta?.teamColor,
        },
      ],
    };
  }

  return {};
});

const chartLabels = computed(() => {
  if (comparePlayerReportData.value) {
    return Array.from({ length: 18 }, (_, index) => index+1);
  }

  if (playerReportData.value) {
    return getGameDateLabels(playerReportData);
  }

  return [];
});

const comparePlayerOptions = computed(() => {
  if (players.value && playerReportData.value?.meta?.position) {
    return players.value.filter((p) => p.position === playerReportData.value.meta.position)
      .map((p) => {
        return { label: p.name, value: p.id };
      });
  }
  return [];
});

const playerDatasetData = computed(() => {
  const stats = [stat.value];
  return getPlayerDatasetData(stats, playerReportData);
});

const comparePlayerDatasetData = computed(() => {
  const stats = [stat.value];
  return getPlayerDatasetData(stats, comparePlayerReportData);
});

const updateComparePlayer = async (e) => {
  const newComparePlayer = players.value.find((player) => player.id === e);
  console.log('new compare player', newComparePlayer)
  if (newComparePlayer && newComparePlayer.id) {
    comparePlayerLoading.value = true;

    const data = await usePlayerDataFetch(
      newComparePlayer.id, 
      newComparePlayer.name,
      2024,
      props.sport,
    );

    comparePlayer.value = e;
    comparePlayerReportData.value = data;
    comparePlayerLoading.value = false;
  }
};

const clearComparePlayer = () => {
  comparePlayer.value = null;
  comparePlayerReportData.value = null;
};

const getGameDateLabels = (report) => {
  if (report.value?.charts?.scatter && stat.value && stat.value) {
    const stats = [stat.value];
    const scatterData = report.value.charts.scatter.filter((group) => stats.includes(group.name));
    const groupValues = scatterData.map((group) => group.values);
    return _.flatten(groupValues).map((d) => d.key);
  }
  return [];
};

const getPlayerDatasetData = (stats, report) => {
  if (report.value?.charts?.scatter && stat.value) {
    const scatterData = report.value.charts.scatter.filter((group) => stats.includes(group.name));
    const groupValues = scatterData.map((group) => group.values);
    return _.flatten(groupValues).map((d) => d.value);
  }
  return [];
};
</script>

<style scoped>
.n-select {
  width: 25%;
}
</style>