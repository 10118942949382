<template>
  <NCard :title="teamName" :bordered="false">
    <template #header-extra>
      <TeamLogo :link="teamLogoLink" :teamId="teamId" />
    </template>
    <NSpace vertical>
      <NText strong>{{ teamConference }}</NText>
    </NSpace>
    <template #action>
      <NFlex>
        <NButton strong secondary @click="navigate('stats')">Stats</NButton>
        <NButton strong secondary>Roster</NButton>
        <NButton strong secondary @click="() => router.push(`/cfb/games/${props.team.school}`)">Games</NButton>
      </NFlex>
    </template>
  </NCard>
</template>

<script setup>
import { computed, defineProps } from 'vue';
import { useRouter } from 'vue-router';
import { NFlex, NSpace, NText, NCard, NButton } from 'naive-ui'; 

import TeamLogo from '@/components/TeamLogo';

const router = useRouter();

const props = defineProps(['sport', 'team', 'identifier']);

const teamName = computed(() => {
  if (props.team.school) {
    return `${props.team.school} ${props.team.mascot}`;
  }
  return props.team.prettyName;
});

const teamId = computed(() => props.team?.id);
const teamConference = computed(() => props.team?.conference);
const teamLogoLink = computed(() => props.team?.logo_primary);

const navigate = (type) => {
  console.log(props.team, props.identifier)
  if (type === 'stats') {
    const link = `/${props.sport}/team/${props.team[props.identifier]}`;
    return router.push(link);
  }
}
</script>

<style scoped>
img {
  height: 40px;
}
.n-card {
  width: 32%;
}
@media (max-width: 600px) {
  .n-card {
    width: 100%;
  }
}
</style>