<template>
  <div class="page-container">
    <div class="cards-container">
      <NFlex align="center">
        <img :src="teamLogo" />
        <NText strong>{{ teamName }}</NText>
        <NText strong>{{ teamRecord }}</NText>
      </NFlex>
      <DataTable
        :data="tableData"
        :columns="tableColumns"
        :loading="tableLoading"
      />
    </div>
  </div>
</template>

<script setup>
import { h, ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { NText, NFlex } from 'naive-ui';
import { formatDate } from '@/util/shared';
import { useSportDataFetch } from '@/composables/SportDataFetch';
import { useGamesFetch } from '@/composables/GamesFetch';

import DataTable from '@/components/DataTable';

const route = useRoute();

const teams = ref([]);

const tableData = ref([]);
const tableColumns = ref([]);
const tableLoading = ref(false);

const team = computed(() => {
  return teams.value.find((t) => t.school === route.params.id);
});

const teamLogo = computed(() => {
  return team?.value?.logo_primary;
});

const teamName = computed(() => {
  return team.value ? `${team.value.school} ${team.value.mascot}` : '';
});

const teamRecord = computed(() => {
  if (!tableData.value) return '';
  const winLoss = tableData.value.map((td) => {
    if (td.home_points || td.away_points) {
      const isHome = team.value.school === td.home_team;
      const score = isHome ? td.home_points : td.away_points;
      const opponentScore = isHome ? td.away_points : td.home_points;
      const won = score > opponentScore;
      return won ? 'w' : 'l';
    }
    return '-';
  });

  const wins = winLoss.filter((wl) => wl === 'w').length;
  const losses = winLoss.filter((wl) => wl === 'l').length;

  return `(${wins}-${losses})`;
});

onMounted(async () => {
  await fetchSportData();
  await fetchGames();
  console.log(team.value)
});

const fetchSportData = async () => {
  const sportData = await useSportDataFetch('cfb');
  teams.value = sportData.teams;
};

const fetchGames = async () => {
  console.log(team.value)
  if (route.params.id) {
    setTableLoading(true);
    setTableColumns();

    const sport = 'cfb';
    const games = await useGamesFetch(
      sport,
      route.params.id
    );

    setTableData(games);
    setTableLoading(false);
    console.log('games', games)
  }
};

const setTableColumns = () => {
  tableColumns.value = [
    {
      key: 'week',
      title: 'Week',
    },
    {
      key: 'start_date',
      title: 'Date',
      render(row) {
        return formatDate(row.start_date);
      },
    },
    {
      key: 'home_team',
      title: 'Opponent',
      render(row) {
        const isHome = team.value.school === row.home_team;
        const opponent = isHome ? row.away_team : row.home_team;
        const indicator = isHome ? 'vs' : '@';
        const opponentObj = teams.value.find((t) => t.school === opponent);
        const src = opponentObj?.logo_primary || '';

        return h(
          NFlex,
          { align: 'center' },
          [
            h(NText, {}, indicator),
            h('img', { style: 'height: 18px;', src }),
            h(NText, {}, opponent)
          ]
        );
      },
    },
    {
      key: 'result',
      title: 'Result',
      render(row) {
        if (row.home_points || row.away_points) {
          const isHome = team.value.school === row.home_team;
          const score = isHome ? row.home_points : row.away_points;
          const opponentScore = isHome ? row.away_points : row.home_points;
          const won = score > opponentScore;
          const indicator = won ? 'W' : 'L';
          const type = won ? 'success' : 'error';
          const points = `${row.away_points}-${row.home_points}`;

          return h(
            NFlex,
            { align: 'center' },
            () => [
              h(NText, { type }, [indicator]),
              h(NText, { strong: true }, [points])
            ]
          );
        }

        return 'scheduled';
      },
    },
  ];
};

const setTableData = (data) => tableData.value = data;
const setTableLoading = (isLoading) => tableLoading.value = isLoading;
</script>

<style scoped>
img {
  height: 40px;
}
</style>