<template>
  <TeamsPage
    :sport="SPORT"
    :identifier="'proFootballReferenceId'"
  />
</template>

<script setup>
import TeamsPage from '@/pages/shared/TeamsPage';

const SPORT = 'nfl';
</script>