<template>
  <NFlex>
    <NText :class="getClass(favorsHome === 'yes')">{{ homeTeam }} {{ homeTotal }}</NText>
    <NText :class="getClass(favorsHome === 'no')">{{ awayTeam }} {{ awayTotal }}</NText>
  </NFlex>
</template>

<script setup>
import { toRef, defineProps, computed } from 'vue';
import { NText, NFlex } from 'naive-ui';

const props = defineProps(['id', 'data']);

const chartData = toRef(props, 'data');

console.log(chartData)

const awayTeam = computed(() => chartData?.value && chartData?.value[props.id]?.length ? chartData.value[props.id][0]?.team : null);
const homeTeam = computed(() => chartData?.value && chartData?.value[props.id]?.length ? chartData.value[props.id][1]?.team : null);

const awayTotal = computed(() => chartData?.value && chartData?.value[props.id]?.length ? chartData.value[props.id][0]?.total : null);
const homeTotal = computed(() => chartData?.value && chartData?.value[props.id]?.length ? chartData.value[props.id][1]?.total : null);

const favorsHome = computed(() => {
  if (awayTotal.value != null && homeTotal.value != null) {
    return homeTotal.value > awayTotal.value ? 'yes' : 'no';
  }
  return 'none';
});

const getClass = (b) => {
  return b ? 'good-color' : 'bad-color';
};
</script>

<style scoped>
.good-color {
  font-weight: 900;
}
</style>