<template>
  <PlayerPage
    :sport="SPORT"
    :rate-stat-fn="getDefaultRateStat"
    :has-extended-rate-stats="true"
  />
</template>

<script setup>
import PlayerPage from '@/pages/shared/PlayerPage';

const SPORT = 'nba';

const getDefaultRateStat = () => {
  return 'pts';
};
</script>
