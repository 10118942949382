<template>
  <div class="page-container">
    <div class="lines-container">
      <NFlex>
        <NSelect />
        <NSelect />
      </NFlex>
      <NDivider />
      <BarChart :chartData="chartData" :options="chartOptions" />
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { NSelect, NText, NFlex, NCard, NSpace, NDivider } from 'naive-ui';
import { BarChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';

import { useLeagueFetch } from '@/composables/LeagueDataFetch';

Chart.register(...registerables);

const SPORT = 'nfl';

const teams = ref([]);
const leagueData = ref([]);
const chartData = ref({});
const chartOptions = ref({
  plugins: {
    legend: false,
  },
  indexAxis: 'y',
  scaleShowValues: true,
  scales: {
    x: { ticks: { autoSkip: false } },
    y: { ticks: { autoSkip: false } }
  },
});

onMounted(async () => await fetchLeagueData());

const fetchLeagueData = async () => {
  const data = await useLeagueFetch('offense', 'passing', SPORT);
  
  teams.value = data.teams;
  leagueData.value = data.leagueData;

  console.log(data.leagueData)

  renderChart(data.leagueData);
};

const renderChart = (data) => {
  chartData.value = {
    labels: data.map((d) => d.team),
    datasets: [
      {
        data: data.map((d) => d.att),
        backgroundColor: data.map((d) => d.color),
      }
    ]
  }
};
</script>

<style scoped>
.n-card {
  width: 48%;
}
@media (max-width: 600px) {
  .n-card {
    width: 100%;
  }
}
</style>