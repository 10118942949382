export const SPORTS = {
  NFL: 'nfl',
  NBA: 'nba',
  MLB: 'mlb',
  CFB: 'cfb',
};

export const formatDate = (str) => {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const dateObj = new Date(str);

  const day = dateObj.getDay();
  const month = dateObj.getMonth();
  const date = dateObj.getDate();

  return `${days[day]}, ${months[month+1]} ${date}`;
}
export const formatTeam = (teamId, teams) => {
  return teams.find((team) => team.id === teamId)?.prettyName;
};

export const formatName = (playerName) => {
  if (!playerName) return;
  const parts = playerName.split('-');
  return parts.map((part) => {
    const formatted = part.charAt(0).toUpperCase() + part.slice(1);
    const formattedLowerCase = formatted.toLowerCase();
    if (['ii', 'iii'].includes(formattedLowerCase)) return formatted.toUpperCase();
    return formatted;
  }).join(' ');
};

export const cellFormatters = {
  player: (value) => {
    return formatName(value);
  },
};

export const cellClassesOvers = {
  opponentRankVsPosition: (value) => {
    if (value === 'N/A') {
      return 'na';
    }

    const int = parseInt(value);

    if (int > 19) {
      return 'good';
    }

    if (int > 5) {
      return 'medium';
    }

    return 'bad';
  },
  hitRateLast5: (value) => {
    if (value === 'N/A') {
      return 'na';
    }
  
    const int = parseInt(value);

    if (int > 75) {
      return 'good';
    }

    if (int > 60) {
      return 'medium';
    }

    return 'bad';
  },
  average: (value, rowData) => {
    return rowData.aboveAverage === 'YES' ? 'good' : 'bad';
  },
  playerStatus: (value) => {
    if (value === 'Active') {
      return 'good';
    }

    if (['QUESTIONABLE', 'GTD'].includes(value)) {
      return 'medium';
    }

    if (value === 'OUT') {
      return 'bad';
    }

    return '';
  },
};

export const cellClassesUnders = {
  opponentRankVsPosition: (value) => {
    const int = parseInt(value);

    if (isNaN(int)) {
      return 'na';
    }

    if (int < 10) {
      return 'good';
    }

    if (int < 20) {
      return 'medium';
    }

    return 'bad';
  },
  hitRateLast5: (value) => {
    const int = parseInt(value);

    if (isNaN(int)) {
      return 'na';
    }

    if (int < 20) {
      return 'good';
    }

    if (int < 40) {
      return 'medium';
    }

    return 'bad';
  },
  average: (value, rowData) => {
    return rowData.aboveAverage === 'YES' ? 'good' : 'bad';
  },
  playerStatus: (value) => {
    if (value === 'Active') {
      return 'good';
    }

    if (['QUESTIONABLE', 'GTD'].includes(value)) {
      return 'medium';
    }

    if (value === 'OUT') {
      return 'bad';
    }

    return '';
  },
};