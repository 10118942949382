<template>
  <NElement class="nav-links">
    <NDropdown
      trigger="click"
      :options="links"
      @select="navigate"
    >
      <NButton secondary>
        <NText strong>Navigate</NText>
      </NButton>
    </NDropdown>
  </NElement>
</template>

<script setup>
import { computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { NElement, NButton, NDropdown, NText } from 'naive-ui';

const router = useRouter();
const route = useRoute();

const navigate = (link) => router.push(link);

const links = computed(() => {
  const sport = route?.fullPath?.split('/')[1];

  if (!sport) {
    return [];
  }

  const nflOptions = [
    { label: 'Games', key: '/nfl/events', sport: 'nfl' },
    { label: 'Teams', key: '/nfl/teams', sport: 'nfl' },
    { label: 'Players', key: '/nfl/players', sport: 'nfl' },
    { label: 'Depth Charts', key: '/nfl/depth-charts', sport: 'nfl' },
    { label: 'League Charts', key: '/nfl/league', sport: 'nfl' },
  ];

  const nbaOptions = [
    { label: 'Games', key: '/nba/events', sport: 'nba' },
    { label: 'Teams', key: '/nba/teams', sport: 'nba' },
    { label: 'Players', key: '/nba/players', sport: 'nba' },
  ];

  const mlbOptions = [
    { label: 'Games', key: '/mlb/events', sport: 'mlb' },
    { label: 'Teams', key: '/mlb/teams', sport: 'mlb' },
    { label: 'Players', key: '/mlb/players', sport: 'mlb' },
    { label: 'Schedule', key: '/mlb/schedule', sport: 'mlb' },
  ];

  const cfbRoutes = [
    { label: 'Games', key: '/cfb/events', sport: 'cfb' },
    { label: 'Teams', key: '/cfb/teams', sport: 'cfb' },
  ];

  const options = [
    ...nflOptions,
    ...nbaOptions,
    ...mlbOptions,
    ...cfbRoutes,
  ];

  return options.filter((o) => o.sport === sport);
});
</script>