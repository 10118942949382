<template>
  <img :src="sportLogo()" class="sport-logo" />
</template>

<script setup>
import { useRoute } from 'vue-router';

const route = useRoute();

const sportLogo = () => {
  const sport = route.fullPath.split('/')[1];
  switch (sport) {
    case 'nba':
      return 'sport-logos/nba.png';
    case 'mlb':
      return 'sport-logos/mlb.png';
    case 'nfl':
      return 'sport-logos/nfl.png';
    case 'cfb':
      return 'sport-logos/football.png';
    case 'cbb':
      return 'sport-logos/basketball.png';
    default:
      return '';
  }
};
</script>