<template>
  <div class="page-container">
    <div class="cards-container">
      <div class="img-container">
        <div>
          <PlayerHeadshot :sport="props.sport" :player="player" />
          <NText>{{ player?.name }} | {{ formatTeam(player?.teamId, teams) }}</NText>
        </div>
        <NText v-if="playerStatus === 'active'" type="success" class="status"><b>ACTIVE</b></NText>
        <NText v-else-if="playerStatus === 'gtd'" type="warning" class="status"><b>GTD</b></NText>
        <NText v-else-if="playerStatus === 'out'" type="error" class="status"><b>OUT</b></NText>
      </div>

      <NSelect
        placeholder="Season Year"
        v-model:value="seasonYear"
        :options="seasonYearSelectOptions"
        :on-update:value="updateSeasonYear"
      />

      <DataTable
        class="averages-table"
        :data="averagesTableData"
        :columns="averagesTableColumns"
        :loading="averagesTableLoading"
      />

      <DataTable
        class="averages-table-transposed"
        :data="averagesTableDataTransposed"
        :columns="averagesTableColumnsTransposed"
        :loading="averagesTableLoadingTransposed"
      />

      <NFlex align="center">
        <NSelect
          placeholder="Rate Table Stat"
          v-model:value="rateTableStat"
          :options="rateTableSelectOptions"
          :on-update:value="updateRateTableStat"
        />

        <NSelect 
          v-if="hasExtendedRateStats"
          v-model:value="rateTableMode"
          :options="rateTableModeOptions"
          :on-update:value="updateRateTableMode"
        />
      </NFlex>

      <DataTable
        :data="rateTableData"
        :columns="rateTableColumns"
        :loading="rateTableLoading"
        :render-cell="renderFormattedCell"
      />

      <PlayerLineChart
        :sport="props.sport"
        :stat="rateTableStat"
        :players="players"
        :playerReportData="apiData"
      />
    </div>
  </div>
</template>

<script setup>
import { h, ref, computed, onMounted, defineProps } from 'vue';
import { useRoute } from 'vue-router';
import { NText, NSelect, NFlex } from 'naive-ui';

import { formatTeam } from '@/util/shared';
import { useSportDataFetch } from '@/composables/SportDataFetch';
import { usePlayerDataFetch } from '@/composables/PlayerDataFetch';
import DataTable from '@/components/DataTable';
import PlayerHeadshot from '@/components/PlayerHeadshot';
import PlayerLineChart from '@/components/PlayerLineChart';

const props = defineProps(['sport', 'rateStatFn', 'hasExtendedRateStats']);

onMounted(async () => {
  setSeasonYearSelect();

  await fetchSportData();
  await fetchPlayerData();

  setRateTableStat();
  setRateTable();
  setAveragesTable();
});

const route = useRoute();
const { id } = route.params;

const teams = ref([]);
const players = ref([]);

const seasonYear = ref(2024);
const seasonYearSelectOptions = ref([]);

const apiData = ref({});

const rateTableStat = ref('');
const rateTableSelectOptions = ref([]);

const rateTableData = ref([]);
const rateTableColumns = ref([]);
const rateTableLoading = ref(true);

const rateTableMode = ref('dynamic');
const rateTableModeOptions = ref([
  { label: 'Dynamic', value: 'dynamic' },
  { label: 'Extended', value: 'extended' },
]);

const averagesTableData = ref([]);
const averagesTableColumns = ref([]);
const averagesTableLoading = ref(true);

const averagesTableDataTransposed = ref([]);
const averagesTableColumnsTransposed = ref([]);
const averagesTableLoadingTransposed = ref(true);

const player = computed(() => {
  return players.value.find((player) => player.id == id) || {};
});

const playerStatus = computed(() => {
  return apiData?.value?.meta?.status?.toLowerCase();
});

const fetchSportData = async () => {
  const sportData = await useSportDataFetch(props.sport);
  teams.value = sportData.teams;
  players.value = sportData.players;
};

const fetchPlayerData = async () => {
  const playerData = await usePlayerDataFetch(
    player.value?.id,
    player.value?.name,
    seasonYear.value,
    props.sport
  );

  apiData.value = playerData;
};

const setRateTableStat = (stat) => {
  if (stat) {
    rateTableStat.value = stat;
  } else if (props.rateStatFn && player?.value?.position) {
    rateTableStat.value = props.rateStatFn(player.value?.position.trim());
  }
  return '';
};

const updateRateTableMode = (e) => {
  const isExtended = e === 'extended';
  if (isExtended) {
    const rateTable = apiData.value?.rate_tables_extended.find((table) => {
      return table.key === rateTableStat.value;
    });
    rateTableData.value = rateTable?.data;
  } else {
    const rateTable = apiData.value?.rate_tables.find((table) => {
      return table.key === rateTableStat.value;
    });
    rateTableData.value = rateTable?.data;
  }

  rateTableMode.value = e;
};

const setRateTable = () => {
  if (!apiData.value?.rate_tables) {
    rateTableLoading.value = false;
    return;
  }

  let rateTable = apiData.value?.rate_tables.find((table) => {
    return table.key === rateTableStat.value;
  });

  if (rateTableMode.value === 'extended') {
    rateTable = apiData.value?.rate_tables_extended.find((table) => {
      return table.key === rateTableStat.value;
    });
  }

  rateTableSelectOptions.value = apiData.value?.rate_tables?.map((table) => {
    const scope = table.scope ? String(table.scope).toUpperCase() : undefined;
    const label = scope ? `${table.name} - ${scope}` : table.name;
    const value = table.key;
    return { label, value };
  });

  rateTableData.value = rateTable?.data;
  rateTableColumns.value = [
    { title: rateTable?.name, key: 'rate', align: 'center', className: 'headers' },
    { title: 'L10', key: 'L10HitPercent', align: 'center' },
    { title: 'L9', key: 'L9HitPercent', align: 'center' },
    { title: 'L8', key: 'L8HitPercent', align: 'center' },
    { title: 'L7', key: 'L7HitPercent', align: 'center' },
    { title: 'L6', key: 'L6HitPercent', align: 'center' },
    { title: 'L5', key: 'L5HitPercent', align: 'center' },
    { title: 'L4', key: 'L4HitPercent', align: 'center' },
    { title: 'L3', key: 'L3HitPercent', align: 'center' },
    { title: 'L2', key: 'L2HitPercent', align: 'center' },
    { title: 'L1', key: 'L1HitPercent', align: 'center' },
  ];
  rateTableLoading.value = false;
};

const setAveragesTable = () => {
  if (!apiData.value?.averages) {
    averagesTableLoading.value = false;
    return;
  }

  averagesTableData.value = apiData.value?.averages;

  averagesTableColumns.value = [
    { title: 'Stat', key: 'stat' },
    { title: 'Avg', key: 'Avg' },
    { title: 'L1Avg', key: 'L1Avg' },
    { title: 'L5Avg', key: 'L5Avg' },
  ];
  averagesTableLoading.value = false;

  setAveragesTableTransposed();
};

const setAveragesTableTransposed = () => {
  if (!apiData.value?.averages_transposed) {
    averagesTableLoadingTransposed.value = false;
    return;
  }

  averagesTableDataTransposed.value = apiData.value?.averages_transposed?.data;
  averagesTableColumnsTransposed.value = apiData.value?.averages_transposed?.columns;
  averagesTableLoadingTransposed.value = false;
};

const setSeasonYearSelect = () => {
  seasonYearSelectOptions.value = [
    { label: '2024', value: 2024 },
    { label: '2023', value: 2023 },
  ];
};

const updateSeasonYear = async (e) => {
  seasonYear.value = e;
  await fetchPlayerData();

  setRateTableStat();
  setRateTable();
  setAveragesTable();
};

const updateRateTableStat = (e) => {
  setRateTableStat(e);
  setRateTable();
};

const renderFormattedCell = (value, rowData, column) => {
  const plainValue = value.split('%')
  const parsedValue = parseFloat(plainValue)

  if (column.className === 'headers') {
    const rowValues = Object.values(rowData);
    rowValues.shift();
    if (rowValues.every((datum) => datum === '100%')) {
      return h(NText, { depth: 2, class: 'l100' }, { default: () => value });
    }
    return h(NText, { depth: 2 }, { default: () => value });
  }

  let className = '';

  if (parsedValue >= 100) {
    className = 'good-5';
  } else if (parsedValue >= 90) {
    className = 'good-4';
  } else if (parsedValue >= 80) {
    className = 'good-3';
  } else if (parsedValue >= 70) {
    className = 'good-2';
  } else if (parsedValue >= 60) {
    className = 'good-1';
  }  else if (parsedValue == 50) {
    className = 'med';
  } else if (parsedValue >= 40) {
    className = 'bad-4';
  } else if (parsedValue >= 30) {
    className = 'bad-3';
  } else if (parsedValue >= 20) {
    className = 'bad-2';
  } else {
    className = 'bad-1';
  }

  return h(NText, { depth: 1, class: className }, { default: () => value });
};
</script>

<style>
  div.tooltip {
    position: absolute;
    text-align: center;
    padding: .2rem;
    background: #313639;
    color: #f9f9f9;
    border: 0px;
    pointer-events: none;
  }

  .img-container {
    height: 100px;
    font-size: 18px;
    border-bottom: 1px solid #f3f3f3;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .n-data-table .n-data-table-td:has(.n-text.bad-1) {
    background-color: #fffefe;
  }
  .n-data-table .n-data-table-td:has(.n-text.bad-2) {
    background-color: #fbfbff;
  }
  .n-data-table .n-data-table-td:has(.n-text.bad-3) {
    background-color: #f8f8ff;
  }
  .n-data-table .n-data-table-td:has(.n-text.bad-4) {
    background-color: #f5f5fc;
  }
  .n-data-table .n-data-table-td:has(.n-text.med) {
    background-color: #f1f2f9;
  }
  .n-data-table .n-data-table-td:has(.n-text.good-1) {
    background-color: #efd083ad;
  }
  .n-data-table .n-data-table-td:has(.n-text.good-2) {
    background-color: #efd183;
  }
  .n-data-table .n-data-table-td:has(.n-text.good-3) {
    background-color: #a0d98d6f;
  }
  .n-data-table .n-data-table-td:has(.n-text.good-4) {
    background-color: #a0d98dc4;
  }
  .n-data-table .n-data-table-td:has(.n-text.good-5) {
    background-color: #a0d98d;
  }
  .button {
    position: absolute;
    top: 0;
  }
  .averages-table {
    display: none;
  }
  .averages-table-transposed {
    display: block;
  }
  .n-select {
    width: 25%;
  }
  @media print {
    .no-print {
      display: none;
    }
  }
  @media (max-width: 600px) {
    .player-info {
      display: none;
    }
    .averages-table {
      display: block;
    }
    .averages-table-transposed {
      display: none;
    }
    .n-select {
      width: 100%;
    }
  } 
</style>
