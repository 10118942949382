<template>
  <EventsPage
    :sport="SPORT"
    :fetch-events="fetchEventLines"
  />
</template>

<script setup>
import EventsPage from '@/pages/shared/EventsPage';

import { useEventLinesDataPost } from '@/composables/EventLinesDataPost';

import {
  SPORT,
  ODDS_API_STUB,
  REGIONS,
  ODDS_FORMAT,
  BOOK_MAKERS,
  AVAILABLE_MARKETS,
} from '@/util/util_cfb';

const fetchEventLines = async () => {
  const MARKETS = AVAILABLE_MARKETS.h2h;

  const params = new URLSearchParams({
    apiKey: process.env.VUE_APP_ODDS_API_KEY,
    regions: REGIONS,
    markets: MARKETS,
    bookmakers: BOOK_MAKERS,
    oddsFormat: ODDS_FORMAT,
  });

  const url = `${ODDS_API_STUB}/?${params}`;

  const response = await fetch(url);
  const json = await response.json();

  if (json.message) {
    return {};
  }

  await useEventLinesDataPost(SPORT, json);
};
</script>