<template>
  <div class="page-container">
    <div class="lines-container">
      <NFlex justify="flex-end" align="center">
        <NText strong>{{ lastPullTimestamp }}</NText>
        <NButton secondary @click="() => loadEvents('yes')">Refresh</NButton>
      </NFlex>
      <NElement style="margin-top: 10px;">
        <NFlex justify="space-between">
          <EventCard v-for="event in events?.data" :key="event.id" :game="event" :sport="props.sport"></EventCard>
        </NFlex>
      </NElement>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, defineProps } from 'vue';
import { NFlex, NText, NButton, NElement } from 'naive-ui';
import { useEventLinesDataFetch } from '@/composables/EventLinesDataFetch';

import EventCard from '@/components/EventCard';

const props = defineProps({
  sport: {
    type: String,
    required: true,
    default() {
      return '';
    },
  },
  fetchEvents: {
    type: Function,
    required: true,
    async default() {
      return {};
    },
  },
});

onMounted(async () => await loadEvents());

const events = ref([]);

const lastPullTimestamp = computed(() => {
  const timestamp = events.value?.timestamp;
  return timestamp ? new Date(timestamp).toLocaleString() : '';
});

const loadEvents = async (refresh = 'no') => {
  const eventsData = await useEventLinesDataFetch(props.sport, refresh);
  events.value = eventsData;
};
</script>

<style>
@media print {
  .no-print {
    display: none;
  }
}
</style>