<template>
  <TeamsPage
    :sport="SPORT"
    :identifier="'id'"
  />
</template>

<script setup>
import TeamsPage from '@/pages/shared/TeamsPage';

const SPORT = 'nba';
</script>