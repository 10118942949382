<template>
  <PlayerPage
    :sport="SPORT" 
    :rate-stat-fn="getDefaultRateStat"
  />
</template>

<script setup>
import PlayerPage from '@/pages/shared/PlayerPage';

const SPORT = 'mlb';

const getDefaultRateStat = (position) => {
  let defaultRateStat = 'P';
  switch (position) {
    case 'RP': 
    case 'SP':
      defaultRateStat = 'P';
      break;
    case 'C':
    case 'SS':
    case '2B':
    case '3B':
    case '1B':
    case 'LF':
    case 'CF':
    case 'OF':
    case 'RF':
    case 'DH':
      defaultRateStat = 'H';
      break;
  }
  return defaultRateStat;
}
</script>