<template>
  <div class="page-container">
    <div class="cards-container">
      <NSelect
        v-model:value="teamSelect"
        :options="teamSelectOptions"
        :on-update:value="updateTeam"
        placeholder="Team"
      />
      <NInput
        v-model:value="playerInput"
        :on-update:value="updatePlayer"
        type="text"
        placeholder="Player Search"
      />
      <DataTable
        :data="tableData"
        :columns="tableColumns"
        :render-cell="renderFormattedCell"
      />
    </div>
  </div>
</template>

<script setup>
import {
  h,
  ref,
  computed,
  onMounted,
  defineProps,
} from 'vue';
import { RouterLink } from 'vue-router';
import { NSelect, NInput } from 'naive-ui';
import { formatTeam } from '@/util/shared';
import { useSportDataFetch } from '@/composables/SportDataFetch';
import DataTable from '@/components/DataTable';
import _ from 'lodash';

const props = defineProps ({
  sport: {
    type: String,
    default: '',
  },
  columns: {
    type: Object,
    default() {
      return [];
    },
  },
  positions: {
    type: Object,
    default() {
      return [];
    },
  },
  nameFormatterFn: {
    type: Function,
    default(value) {
      return value;
    },
  },
});

onMounted(async () => {
  await fetchSportData();
  setTableData();
});

const teams = ref([]);
const players = ref([]);

const tableData = ref([]);
const tableColumns = ref([]);

const playerInput = ref('');
const teamSelect = ref('all');

const fetchSportData = async () => {
  const sportData = await useSportDataFetch(props.sport);

  teams.value = sportData.teams;
  players.value = sportData.players.filter((p) => {
    if (props.positions.length) {
      return props.positions.includes(p.position);
    }
    return p;
  });
};

const sortPlayers = (selections = []) => {
  return _.orderBy(selections, ['teamId', 'position'], ['asc', 'asc'])
};

const teamSelectOptions = computed(() => {
  let options = [
    { id: 'all', prettyName: 'All Teams' },
  ];

  options = [...options, ...teams.value];

  return options.map((opt) => {
    return {
      value: opt.id,
      label: opt.prettyName,
    };
  });
});

const setTableData = () => {
  tableData.value = sortPlayers(players.value);
  tableColumns.value = props.columns;
};

const updateTeam = (e) => {
  const selections = e === 'all'
    ? players.value
    : players.value.filter((player) => player.teamId === e);

  teamSelect.value = e;
  playerInput.value = '';
  tableData.value = sortPlayers(selections);
};

const updatePlayer = (e) => {
  const selections = players.value.filter((player) => {
    const searchValue = e?.toLowerCase();
    return player.name?.toLowerCase().includes(searchValue);
  });
  playerInput.value = e;
  teamSelect.value = 'all';
  tableData.value = sortPlayers(selections);
};

const renderFormattedCell = (value, rowData, column) => {
  if (column.key === 'name') {
    const to = `/${props.sport}/player/${rowData.id}`;
    return h(RouterLink, { to }, { default: () => props.nameFormatterFn(value) });
  } else if (column.key === 'teamId') {
    return formatTeam(rowData.teamId, teams.value);
  }
  return value;
}
</script>
