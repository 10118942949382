<template>
  <NSelect
    v-model:value="sport"
    :options="sportSelectOptions"
    :on-update:value="updateSport"
    placeholder="Sport"
  />
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { NSelect } from 'naive-ui';

const router = useRouter();
const route = useRoute();

const sport = computed(() => {
  return route?.fullPath?.split('/')[1];
});

const sportSelectOptions = ref([
  { label: 'NFL', value: 'nfl' },
  { label: 'MLB', value: 'mlb' },
  { label: 'NBA', value: 'nba' },
  { label: 'NCAAF', value: 'cfb' },
]);

const updateSport = (e) => {
  router.push({ path: `/${e}`, replace: true });
};
</script>

<style scoped>
.n-select {
  width: 150px;
}
</style>

