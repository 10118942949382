<template>
  <TeamsPage
    :sport="SPORT"
    :show-conference-select="true"
    :default-conference="'Big Ten'"
  />
</template>

<script setup>
import TeamsPage from '@/pages/shared/TeamsPage';

const SPORT = 'cfb';
</script>