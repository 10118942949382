<template>
  <div class="page-container">
    <div class="cards-container">
      <NSelect
        v-model:value="selectedDate"
        :options="dateOptions"
        :on-update:value="updateSelectedDate"
      />
      <DataTable
        class="no-print"
        :data="tableData"
        :columns="tableColumns"
        :loading="tableLoading"
        :render-cell="renderCell"
      />
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { h, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { NIcon, NCard, NSelect, NText } from 'naive-ui';
import { ChartBar } from '@vicons/carbon';
import DataTable from '@/components/DataTable';

const router = useRouter();

const teams = ref([]);
const players = ref([]);

const selectedDate = ref(0);
const dateOptions = ref([]);

const scheduleData = ref({});
const tableData = ref([]);
const tableColumns = ref([
  { title: 'Away', key: 'away', align: 'end' },
  { title: 'Home', key: 'home', align: 'end' },
  { title: 'Status', key: 'status', align: 'center' },
]);
const tableLoading = ref(false);

const setOptions = (data = {}) => {
  dateOptions.value = data.days.map((item, index) => {
    return {
      label: item,
      value: index,
    };
  });
};

const setTables = (index) => {
  const data = scheduleData.value.rows[index];
  tableData.value = data;
  tableLoading.value = false;
};

const updateSelectedDate = (e) => {
  selectedDate.value = e;
  setTables(e);
};

const renderCell = (value, rowData, column) => {
  const { key } = column;
  if (['away', 'home'].includes(key)) {
    const team = teams.value.find((team) => team.name == value);
    if (team) {
      const src = `/mlb-logos/${value}.png`;
      return h(
        'div',
        { class: 'team-inline-container' },
        [
          h('img', { src, class: 'mlb-table-logo' }),
          h('NText', team?.prettyName),
        ],
      )
    }
  }
  return value;
};

const getSchedule = async () => {
  tableLoading.value = true;

  const endpoint = process.env.VUE_APP_INTERNAL_API_ENDPOINT;
  const url = `${endpoint}/api/mlb/schedule`;

  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });

  const json = await response.json();
  scheduleData.value = json;

  setOptions(json);
  setTables(0);
};

const fetchMLbData = async () => {
  const endpoint = process.env.VUE_APP_INTERNAL_API_ENDPOINT;
  const url = `${endpoint}/api/mlb/data`;
  
  const response = await fetch(url);
  const data = await response.json();

  teams.value = data.teams;
  players.value = data.players;
  tableData.value = data.players;
};

onMounted(async () => {
  await fetchMLbData();
  await getSchedule();
});

</script>

<style>
.mlb-table-logo {
  width: 20px;
  margin-right: 10px;
}
.team-inline-container {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
}
</style>
