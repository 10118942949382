<template>
  <img :src="getPlayerHeadshot(player)">
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
  sport: {
    type: String,
    required: true,
    default: '',
  },
  player: {
    type: Object,
    required: true,
    default() {
      return {};
    },
  },
});

const getPlayerHeadshot = (player) => {
  if (player && player.id) {
    switch (props.sport) {
      case 'nba':
        return `https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/${player.id}.png&w=138&h=100`;
      case 'nfl':
        return `https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/${player.id}.png&w=138&h=100`;
      case 'mlb':
        return `https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/${player.id}.png&w=138&h=100`;
    }
  }
};
</script>