export const SPORT = 'nfl';
export const REGIONS = 'us';
export const ODDS_FORMAT = 'american';
export const BOOK_MAKERS = 'draftkings';
export const AVAILABLE_MARKETS = {
  h2h: 'h2h',
  spreads: 'spreads',
  totals: 'totals',
};
export const AVAILABLE_PLAYER_PROPS = [
  'player_pass_tds',
  'player_pass_yds',
  'player_pass_completions',
  'player_pass_attempts',
  'player_pass_interceptions',
  'player_pass_longest_completion',
  'player_rush_yds',
  'player_rush_attempts',
  'player_rush_longest',
  'player_receptions',
  'player_reception_yds',
  'player_reception_longest',
  'player_kicking_points',
  'player_field_goals',
  'player_tackles_assists',
  'player_1st_td',
  'player_last_td',
  'player_anytime_td',
];
export const ODDS_API_STUB = 'https://api.the-odds-api.com/v4/sports/americanfootball_nfl/odds';