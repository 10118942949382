<template>
  <div class="page-container">
    <div class="cards-container">
      <NSelect
        v-model:value="selectedTeam"
        :options="teamOptions"
        :on-update:value="updateSelectedTeam"
      />
      <DataTable
        class="no-print"
        :data="tableData"
        :columns="tableColumns"
        :loading="tableLoading"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { NSelect } from 'naive-ui';

import DataTable from '@/components/DataTable';
import { useSportDataFetch } from '@/composables/SportDataFetch';

onMounted(async () => {
  await fetchSportData();
  setOptions();
  updateSelectedTeam(teams.value[0].id);
  await fetchDepthChart();
});

const teams = ref([]);
const players = ref();

const selectedTeam = ref('');
const teamOptions = ref([]);

const depthChartData = ref([]);
const tableData = ref([]);
const tableColumns = ref([
  { title: 'Team', key: 'team' },
  { title: 'Position', key: 'position' },
  { title: 'Starter', key: 'starter' },
  { title: '2nd', key: 'second' },
  { title: '3rd', key: 'third' },
  { title: '4th', key: 'fourth' },
]);
const tableLoading = ref(false);

const fetchSportData = async () => {
  const sportData = await useSportDataFetch('nfl');
  teams.value = sportData.teams;
  players.value = sportData.players;
};

const fetchDepthChart = async () => {
  tableLoading.value = true;

  const params = new URLSearchParams({ teamId: selectedTeam.value });
  const endpoint = process.env.VUE_APP_INTERNAL_API_ENDPOINT;
  const url = `${endpoint}/api/nfl/depth-charts?${params}`;

  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });

  const json = await response.json();
  const depthChart = json.depthChart;

  if (depthChart) {
    depthChartData.value = depthChart;
  }

  setTables();
}

const setOptions = () => {
  teamOptions.value = teams.value.map((team) => {
    return { label: team.prettyName, value: team.id };
  });
};

const setTables = () => {
  const data = depthChartData.value;

  tableData.value = data;
  tableLoading.value = false;
};

const updateSelectedTeam = (e) => {
  selectedTeam.value = e;
  fetchDepthChart(e);
};
</script>

<style>
.mlb-table-logo {
  width: 20px;
  margin-right: 10px;
}
.team-inline-container {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
}
</style>
