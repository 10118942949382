<template>
  <div class="page-container">
    <div class="cards-container">
      charts
      <div class="chart" ref="chartContainer"></div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';

const api = async () => {
  const endpoint = process.env.VUE_APP_INTERNAL_API_ENDPOINT;

  const url = `${endpoint}/api/nba/league`;
  console.log(url)
  const response = await fetch(url);

  return await response.json();
};

const tryFetch = async () => {
  await api();
};

onMounted(async () => {
  await tryFetch();
});
</script>

<style scoped>
  div.tooltip {
    position: absolute;
    text-align: center;
    padding: .2rem;
    background: #313639;
    color: #f9f9f9;
    border: 0px;
    pointer-events: none;
  }
  .chart {
    margin-top: 10px;
    border: 1px solid #3f3f3f;
    border-radius: 3px;
    background-color: #18181c;
  }
  .n-data-table .n-data-table-td:has(.n-text.l1) {
    background-color: #87899A;
  }
  .n-data-table .n-data-table-td:has(.n-text.l2) {
    background-color: #787A8E;
  }
  .n-data-table .n-data-table-td:has(.n-text.l3) {
    background-color: #696B81;
  }
  .n-data-table .n-data-table-td:has(.n-text.l4) {
    background-color: #5A5D75;
  }
  .n-data-table .n-data-table-td:has(.n-text.l5) {
    background-color: #4B4E68;
  }
  .n-data-table .n-data-table-td:has(.n-text.l6) {
    background-color: #3C3F5B;
  }
  .n-data-table .n-data-table-td:has(.n-text.l7) {
    background-color: #2D304F;
  }
  .n-data-table .n-data-table-td:has(.n-text.l8) {
    background-color: #1E2242;
  }
  .n-data-table .n-data-table-td:has(.n-text.l9) {
    background-color: #0F1336;
  }
  .n-data-table .n-data-table-td:has(.n-text.l10) {
    background-color: #000429;
  }
  .button {
    position: absolute;
    top: 0;
  }
  @media print {
    .no-print {
      display: none;
    }
  }
</style>
