<template>
  <div class="page-container">
    <div class="cards-container">
      <div class="img-container">
        <NFlex justify="start" align="center">
          <TeamLogo :teamId="team?.id" :size="'large'" />
          <NText>{{ team?.prettyName }}</NText>
        </NFlex>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { h, ref, computed, onMounted, defineProps } from 'vue';
import { useRoute } from 'vue-router';
import { NText, NSelect, NFlex } from 'naive-ui';

import { useSportDataFetch } from '@/composables/SportDataFetch';
import { useTeamDataFetch } from '@/composables/TeamDataFetch';
import DataTable from '@/components/DataTable';
import TeamLogo from '@/components/TeamLogo';

const props = defineProps(['sport']);

onMounted(async () => {
  await fetchSportData();
  await fetchTeamData();
});

const route = useRoute();
const { id } = route.params;

const teams = ref([]);
const teamData = ref({});

const team = computed(() => teams?.value?.find((t) => t.id === id));

const fetchSportData = async () => {
  const sportData = await useSportDataFetch(props.sport);
  teams.value = sportData.teams;
};

const fetchTeamData = async () => {
  const apiData = await useTeamDataFetch(team.value?.id, props.sport);
  console.log(apiData)
  teamData.value = apiData?.data;
};
</script>

<style>
  div.tooltip {
    position: absolute;
    text-align: center;
    padding: .2rem;
    background: #313639;
    color: #f9f9f9;
    border: 0px;
    pointer-events: none;
  }

  .img-container {
    height: 100px;
    font-size: 18px;
    border-bottom: 1px solid #f3f3f3;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .n-data-table .n-data-table-td:has(.n-text.bad-1) {
    background-color: #fffefe;
  }
  .n-data-table .n-data-table-td:has(.n-text.bad-2) {
    background-color: #fbfbff;
  }
  .n-data-table .n-data-table-td:has(.n-text.bad-3) {
    background-color: #f8f8ff;
  }
  .n-data-table .n-data-table-td:has(.n-text.bad-4) {
    background-color: #f5f5fc;
  }
  .n-data-table .n-data-table-td:has(.n-text.med) {
    background-color: #f1f2f9;
  }
  .n-data-table .n-data-table-td:has(.n-text.good-1) {
    background-color: #efd083ad;
  }
  .n-data-table .n-data-table-td:has(.n-text.good-2) {
    background-color: #efd183;
  }
  .n-data-table .n-data-table-td:has(.n-text.good-3) {
    background-color: #a0d98d6f;
  }
  .n-data-table .n-data-table-td:has(.n-text.good-4) {
    background-color: #a0d98dc4;
  }
  .n-data-table .n-data-table-td:has(.n-text.good-5) {
    background-color: #a0d98d;
  }
  .button {
    position: absolute;
    top: 0;
  }
  .averages-table {
    display: none;
  }
  .averages-table-transposed {
    display: block;
  }
  .n-select {
    width: 25%;
  }
  @media print {
    .no-print {
      display: none;
    }
  }
  @media (max-width: 600px) {
    .player-info {
      display: none;
    }
    .averages-table {
      display: block;
    }
    .averages-table-transposed {
      display: none;
    }
    .n-select {
      width: 100%;
    }
  } 
</style>
