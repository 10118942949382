export async function useTeamDataFetch(id, sport) {
  const endpoint = process.env.VUE_APP_INTERNAL_API_ENDPOINT;

  const params = new URLSearchParams({ id });

  const url = `${endpoint}/api/${sport}/team?${params}`;
  const response = await fetch(url);

  return await response.json();
}
