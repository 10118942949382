import { createRouter, createWebHashHistory } from 'vue-router';

import EventsPageCFB from '@/pages/cfb/EventsPageCFB';
import MatchupPageCFB from '@/pages/cfb/MatchupPageCFB';
import TeamsPageCFB from '@/pages/cfb/TeamsPageCFB';
import GamesPageCFB from '@/pages/cfb/GamesPageCFB';

import TeamPageNFL from '@/pages/nfl/TeamPageNFL';
import TeamsPageNFL from '@/pages/nfl/TeamsPageNFL';
import PlayerPageNFL from '@/pages/nfl/PlayerPageNFL';
import PlayersPageNFL from '@/pages/nfl/PlayersPageNFL';
import DepthChartsPageNFL from '@/pages/nfl/DepthChartsPageNFL';
import MatchupPageNFL from './pages/nfl/MatchupPageNFL';
import LeaguePageNFL from './pages/nfl/LeaguePageNFL';
import EventsPageNFL from '@/pages/nfl/EventsPageNFL';
import PlayerPropsPageNFL from '@/pages/nfl/PlayerPropsPageNFL';

import TeamsPageMLB from '@/pages/mlb/TeamsPageMLB';
import PlayerPageMLB from '@/pages/mlb/PlayerPageMLB';
import PlayersPageMLB from '@/pages/mlb/PlayersPageMLB';
import SchedulePageMLB from '@/pages/mlb/SchedulePageMLB';
import EventsPageMLB from '@/pages/mlb/EventsPageMLB';
import PlayerPropsPageMLB from '@/pages/mlb/PlayerPropsPageMLB';

import TeamsPageNBA from './pages/nba/TeamsPageNBA';
import PlayerPageNBA from '@/pages/nba/PlayerPageNBA';
import PlayersPageNBA from '@/pages/nba/PlayersPageNBA';
import LeagueChartsPageNBA from '@/pages/nba/LeagueChartsPageNBA';
import EventsPageNBA from '@/pages/nba/EventsPageNBA';
import PlayerPropsPageNBA from '@/pages/nba/PlayerPropsPageNBA';

const baseRoutes = [
  { path: '/', redirect: '/nfl/events' },
];

const nflRoutes = [
  { path: '/nfl', redirect: '/nfl/events' },
  { path: '/nfl/teams', component: TeamsPageNFL },
  { path: '/nfl/team/:id', component: TeamPageNFL },
  { path: '/nfl/players', component: PlayersPageNFL },
  { path: '/nfl/player/:id', component: PlayerPageNFL },
  { path: '/nfl/depth-charts', component: DepthChartsPageNFL },
  { path: '/nfl/matchup/:id', component: MatchupPageNFL },
  { path: '/nfl/league/', component: LeaguePageNFL },
  { path: '/nfl/events', component: EventsPageNFL },
  { path: '/nfl/events/:id', component: PlayerPropsPageNFL },
];

const mlbRoutes = [
  { path: '/mlb', redirect: '/mlb/events' },
  { path: '/mlb/teams', component: TeamsPageMLB },
  { path: '/mlb/players', component: PlayersPageMLB },
  { path: '/mlb/player/:id', component: PlayerPageMLB },
  { path: '/mlb/schedule', component: SchedulePageMLB },
  { path: '/mlb/events', component: EventsPageMLB },
  { path: '/mlb/events/:id', component: PlayerPropsPageMLB },
];

const nbaRoutes = [
  { path: '/nba', redirect: '/nba/events' },
  { path: '/nba/teams', component: TeamsPageNBA },
  { path: '/nba/players', component: PlayersPageNBA },
  { path: '/nba/player/:id', component: PlayerPageNBA },
  { path: '/nba/league/charts', component: LeagueChartsPageNBA },
  { path: '/nba/events', component: EventsPageNBA },
  { path: '/nba/events/:id', component: PlayerPropsPageNBA },
];

const cfbRoutes = [
  { path: '/cfb', redirect: '/cfb/events' },
  { path: '/cfb/events', component: EventsPageCFB },
  { path: '/cfb/teams', component: TeamsPageCFB },
  { path: '/cfb/games/:id', component: GamesPageCFB },
  { path: '/cfb/matchup/:id', component: MatchupPageCFB },
];

const routes = [
  ...baseRoutes,
  ...mlbRoutes,
  ...nbaRoutes,
  ...cfbRoutes,
  ...nflRoutes,
];

export const router = createRouter({
  routes,
  history: createWebHashHistory(),
});
