import { createApp } from 'vue';
import App from '@/App';
import { router } from '@/router';
import VueHtmlToPaper from 'vue-html-to-paper';

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}

createApp(App)
  .use(router)
  .use(VueHtmlToPaper, options)
  .mount('#app');
