<template>
  <PlayersPage :sport="SPORT" :columns="TABLE_COLUMNS" />
</template>

<script setup>
import PlayersPage from '@/pages/shared/PlayersPage';

const SPORT = 'nba';
const TABLE_COLUMNS = [
  { title: 'Player', key: 'name' },
  { title: 'Position', key: 'position' },
  { title: 'Team', key: 'teamId' },
];
</script>
