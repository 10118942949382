<template>
  <div class="page-container">
    <div class="lines-container">
      <NCard>
        <NFlex justify="space-between" align="center">
          <NFlex align="center">
            <NFlex>
              <TeamLogo :link="homeTeam.logo_primary" />
              <NText strong>{{ homeTeam.school }}</NText>
              <NText>{{ homeTeam.record }}</NText>
            </NFlex>
            <NFlex strong v-if="homeTeam && awayTeam">
              <NText>@</NText>
              <TeamLogo :link="awayTeam.logo_primary" />
              <NText strong>{{ awayTeam.school }}</NText>
              <NText>{{ awayTeam.record }}</NText>
            </NFlex>
          </NFlex>

          <NText>Predicted Over/Under: {{ overUnder }}</NText>
        </NFlex>
      </NCard>
      <NDivider />
      <NTabs type="line" animated>
        <NTabPane name="tables" tab="Tables">
          <DataTable
            class="no-print"
            :data="tableData"
            :columns="tableColumns"
            :loading="tableLoading"
            :render-cell="renderTableCell"
          />
        </NTabPane>
        <NTabPane name="charts" tab="Charts">
          <NFlex>
            <NCard v-for="chart of charts" :key="chart.key" :title="chart.key">
              <MatchupChartInfo :id="chart.key" :data="matchupData?.chartData" />
              <LineChart :chartData="chart.chartData" :options="chartOptions" />
            </NCard>
          </NFlex>
        </NTabPane>
      </NTabs>
    </div>
  </div>
</template>

<script setup>
import { h, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import { NText, NFlex, NCard, NDivider, NTabs, NTabPane } from 'naive-ui';

import { LineChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';

import { useMatchupFetch } from '@/composables/MatchupDataFetch';

import TeamLogo from '@/components/TeamLogo';
import DataTable from '@/components/DataTable';
import MatchupChartInfo from '@/components/MatchupChartInfo.vue';

Chart.register(...registerables);

const SPORT = 'cfb';
const route = useRoute();

const matchupData = ref({});

const awayTeam = ref({});
const homeTeam = ref({});

const charts = ref([]);

const tableData = ref([]);
const tableColumns = ref([
  { title: '', key: 'stat' },
  { title: '', key: 'awayTeamTotal', align: 'center' },
  { title: '', key: 'homeTeamTotal', align: 'center' },
]);
const tableLoading = ref(false);

const overUnder = ref(0);

const chartOptions = {
  responsive: true,
};

onMounted(async () => await fetchMatchup());

const fetchMatchup = async () => {
  const id = route.params.id;

  if (!id) return;

  tableLoading.value = true;

  const teamIds = id.split('-');
  const apiData = await useMatchupFetch(teamIds[0], teamIds[1], SPORT);

  matchupData.value = apiData;

  const teams = apiData.teams;
  const keyMetrics = apiData.keyMetrics;
  const chartData = apiData.chartData;
  const transposedTableData = apiData.transposedTableData;

  awayTeam.value = teams.away;
  homeTeam.value = teams.home;
  overUnder.value = keyMetrics.overUnder;

  charts.value = Object.keys(chartData).map((key) => {
    const data = chartData[key];
    return transformData(key, data, keyMetrics.maxGamesPlayed || 10);
  });

  tableData.value = transposedTableData;
  tableLoading.value = false;
};

const transformData = (key, data, length) => {
  const chartData = {
    labels: Array.from({ length }, (_, index) => index+1),
    datasets: data.map((d) => {
      return {
        label: d.team,
        data: d.values,
        borderColor: d.color,
      }
    })
  };

  return { key, chartData };
};

const renderTableCell = (value, rowData, column) => {
  const options = { strong: true };

  if (column.key === 'awayTeamTotal' && isNaN(value)) {
    return h(TeamLogo, { link: awayTeam.value.logo_primary }, { default: () => value });
  }

  if (column.key === 'homeTeamTotal' && isNaN(value)) {
    return h(TeamLogo, { link: homeTeam.value.logo_primary }, { default: () => value });
  }

  if (column.key === 'awayTeamTotal') {
    if (rowData.awayTeamTotal > rowData.homeTeamTotal) {
      options.class = 'good';
    }
  }

  if (column.key === 'homeTeamTotal') {
    if (rowData.homeTeamTotal > rowData.awayTeamTotal) {
      options.class = 'good';
    }
  }

  return h(NText, options, { default: () => value });
};
</script>

<style scoped>
.n-card {
  background-color: #f3f3f3;
}
.n-data-table
.n-data-table-td:has(.n-text.good) {
  background-color: #a0d98d;
}
.n-data-table
.n-data-table-td:has(.n-text.medium) {
  background-color: #efd083ad;
}
.n-data-table
.n-data-table-td:has(.n-text.bad) {
  background-color: #ff9a93;
}
@media (max-width: 600px) {
  .n-card {
    width: 100%;
  }
}
</style>