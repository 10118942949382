export async function usePlayerDataFetch(id, name, year, sport) {
  const endpoint = process.env.VUE_APP_INTERNAL_API_ENDPOINT;

  const defaultPlayerInfo = getDefaultPlayerInfo(sport);

  const params = new URLSearchParams({
    id: id || defaultPlayerInfo.id,
    name: name || defaultPlayerInfo.name,
    year: year || new Date().getFullYear(),
  });

  const url = `${endpoint}/api/${sport}/player?${params}`;
  const response = await fetch(url);

  return await response.json();
}

const getDefaultPlayerInfo = (sport) => {
  switch (sport) {
    case 'mlb':
      return { id: '39832', name: 'shohei-ohtani' };
    case 'nba':
      return { id: '4594268', name: 'anthony-edwards' };
    case 'nfl':
      return { id: '3918298', name: 'josh-allen' };
  }
}
