export const SPORT = 'mlb';
export const REGIONS = 'us';
export const ODDS_FORMAT = 'american';
export const BOOK_MAKERS = 'draftkings';
export const AVAILABLE_MARKETS = {
  h2h: 'h2h',
  spreads: 'spreads',
  totals: 'totals',
};
export const AVAILABLE_PLAYER_PROPS = [
  'batter_home_runs',
  // 'batter_hits',
  // 'batter_rbis',
  // 'batter_runs_scored',
  // 'batter_doubles',
  // 'batter_triples',
  // 'batter_walks',
  // 'batter_strikeouts',
  // 'batter_stolen_bases',
  // 'pitcher_strikeouts',
  // 'pitcher_hits_allowed',
  // 'pitcher_walks',
];
export const ODDS_API_STUB='https://api.the-odds-api.com/v4/sports/baseball_mlb/odds/';